<template>
    <div :id="editorId">
        <div class="mb-2">
            <div class="row">
                <div class="col-12">
                    <div :class="alertClass" role="alert" :data-id="id">
                        <div class="me-auto">
                            <span v-if="this.isBlogPost">WEEKLY BLOG POST </span>{{ parsedPostDate }} 
                        </div>
                        <button v-on:click="deletePost()" class="btn btn-light btn-sm mr-1"><font-awesome-icon
                                    icon="fa-solid fa-trash-alt" /> Clear</button>
                        <div v-if="this.hasChanges || !this.mutatedLocked">
                            <button v-on:click="lockPost()" class="btn btn-primary btn-sm"><font-awesome-icon
                                    icon="fa-solid fa-lock" /> Lock</button>
                        </div>
                        <div v-else>
                            <button v-on:click="unlockPost()" class="btn btn-primary btn-sm"><font-awesome-icon
                                    icon="fa-solid fa-lock-open" /> Unlock</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <h6>Longer Post Content</h6>
                    <small>For Facebook and LinkedIn</small>
                    <div class="mb-1 mt-2">
                        <label>Post</label>
                        <textarea v-model="postData" class="form-control" placeholder="Enter your Longer Post Content here"
                            rows="5"></textarea>
                        <span class="text-danger u-hidden validate-post">Please enter your social post</span>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <h6>Shorter Post Content</h6>
                    <small>For Twitter X</small>
                    <div class="mb-1 mt-2">
                        <label>Post</label>
                        <textarea :id="textAreaId" class="form-control" v-model="twitterData"
                            placeholder="Enter your Shorter Post Content here" rows="5"></textarea>
                        <span class="text-danger u-hidden validate-twitter">Please enter your twitter post</span>
                    </div>
                    <div class="mb-3">
                        <span class="character-count">{{ showCharacterCount }}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="mb-2">
                        <label>Hashtags</label>
                        <input v-model="hashtagData" type="text" class="form-control"
                            placeholder="Enter each hashtag followed by a space" />
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label>Link URL</label>
                        <input v-model="linkData" type="text" class="form-control"
                            placeholder="Add http or https before the URL" />
                        <small v-if="this.isBlogPost && !this.hasLink" class="form-text text-muted">
                            Paste your blog post URL here or, better still, install our AutoBlog plugin in WordPress and 
                            it will happen automatically (if new blogs are set to auto publish)
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { createToast } from 'mosha-vue-toastify';

export default {
    props: [
        "id",
        "isBlogPost",
        "postDate",
        "parsedPostDate",
        "postMessage",
        "twitterMessage",
        "hashtags",
        "linkUrl",
        "edited",
        "locked",
        "confirmed",
        "dateFormat",
    ],
    data() {
        return {
            linkData: "",
            postData: "",
            twitterData: "",
            hashtagData: "",
            characterCount: 0,
            showCharacterCount: "Total Characters: 0",
            hasLink: false,
            mutatedEdited: false,
            mutatedConfirmed: false,
            mutatedLocked: false,
            hasChanges: false,
            firstLoad: true,
            hashtagLength: 0,
        }
    },
    created() {
        this.postData = this.postMessage;
        this.twitterData = this.twitterMessage;
        this.hashtagData = this.hashtags;
        this.linkData = this.linkUrl;
        this.mutatedEdited = this.edited;
        this.mutatedConfirmed = this.confirmed;
        this.mutatedLocked = this.locked;

        if (this.linkData != null) {
            this.hasLink = true;
        }
    },
    mounted() {
        this.hasChanges = false;

        this.emitter.on(this.id, () => {
            this.mutatedLocked = false;
        })
    },
    computed: {
        alertClass: function () {

            if (this.hasChanges)
            {
                return "alert alert-small alert-warning d-flex";
            }

            switch (this.mutatedLocked) {
                case true:
                    return "alert alert-small alert-success d-flex";
                case false:
                    return "alert alert-small alert-warning d-flex";
            }
        },
        editorId: function () {
            return "#" + this.id;
        },
        textAreaId: function () {
            return '#shortPost-' + this.id;
        },
    },
    watch: {
        postData() {
            this.hasChanges = true;
        },
        twitterData() {
            this.hasChanges = true;

            if (this.mutatedEdited == true) {
                this.alertClass = "alert alert-small alert-warning d-flex";
            }

            this.characterCount = 0; 

            if (this.twitterData === null)
            {
                return;
            }

            if (this.hashtagData != null && this.hashtagData.length > 0) {
                this.characterCount = this.twitterData.length + this.hashtagData.length;
            }
            else {
                this.characterCount = this.twitterData.length;
            }

            if (this.hasLink == true) {
                this.characterCount += 24;
            }
        },
        hashtagData() {
            if (this.hashtagData == null) {
                return;
            }

            this.hasChanges = true;

            this.characterCount = this.characterCount + (this.hashtagData.length - this.hashtagLength);
            this.hashtagLength = this.hashtagData.length;
        },
        linkData() {
            this.hasChanges = true;

            if (this.linkData != null && this.hasLink == false) {
                this.mutatedLocked = false;
                this.hasLink = true;
                this.characterCount += 24;
                return;
            }

            if (this.linkData == null) {
                this.hasLink = false;
                return;
            }

            if (this.linkData == "") {
                this.hasLink = false;
                this.characterCount -= 24;
                return;
            }

            if (this.hasLink == false) {
                this.hasLink = true;
                this.characterCount += 24;
            }
        },
        characterCount() {
            const characterSpan = document.querySelector('.character-count');
            const textArea = document.getElementById(this.textAreaId);

            if (this.characterCount > 280) {
                characterSpan.classList.add("text-danger");
                characterSpan.classList.add("font-weight-bold");
                this.showCharacterCount = `Total Characters: ${this.characterCount} (exceeds the limit of 280 characters)`;
                textArea.classList.add('overlimit');
            }
            else {
                if (characterSpan.classList.contains("text-danger")) {
                    characterSpan.classList.remove("text-danger");
                    characterSpan.classList.remove("font-weight-bold");
                }
                textArea.classList.remove('overlimit');
                this.showCharacterCount = `Total Characters: ${this.characterCount}`;
            }
        }
    },
    methods: {
        lockPost() {
            const validate = this.validate();

            if (validate == false) {
                return;
            }

            const socialPost = {
                'id': this.id,
                'edited': this.mutatedEdited,
                'postDate': this.postDate,
                'message': this.postData,
                'twitterVariant': this.twitterData,
                'hashtags': this.hashtagData,
                'linkURL': this.linkData,
                'userId': $('#UserId').val(),
                'locked': true,
                'isBlogPost': this.isBlogPost,
            };

            const apiEndpoint = '/api/custompost/save/';

            axios({
                url: apiEndpoint,
                method: "POST",
                data: socialPost
            }).then(response => {
                createToast('Post locked', { position: 'bottom-right', type: 'success', showIcon: true });
                this.mutatedEdited = true;
                this.mutatedConfirmed = true;
                this.mutatedLocked = true;
                this.hasChanges = false;
                this.emitter.emit("update-rss", true);
            });
        },
        unlockPost() {
            const socialPost = {
                'id': this.id,
                'edited': true,
                'postDate': this.postDate,
                'message': this.postData,
                'twitterVariant': this.twitterData,
                'hashtags': this.hashtagData,
                'linkURL': this.linkData,
                'userId': $('#UserId').val(),
                'locked': false,
                'isBlogPost': this.isBlogPost,
            };

            const apiEndpoint = '/api/custompost/save/';

            axios({
                url: apiEndpoint,
                method: "POST",
                data: socialPost
            }).then(response => {
                this.mutatedEdited = true;
                this.mutatedLocked = false;
                createToast('Post unlocked', { position: 'bottom-right', type: 'success', showIcon: true });
                this.hasChanges = true;
            });
        },
        deletePost() {
            const apiEndpoint = `/api/custompost/delete/?id=${this.id}`;

            axios({
                url: apiEndpoint,
                method: "POST"
            }).then(response => {
                console.log(response);
                this.emitter.emit("clear-post");
                createToast('Post cleared', { position: 'bottom-right', type: 'success', showIcon: true });
            });
        },
        validate() {

            if (this.characterCount > 280) {
                createToast('Your Twitter post must be 200 characters or less', { position: 'bottom-right', type: 'warning', showIcon: true });
                return false;
            }

            return true;
        }
    }
};

</script>