<template>
    <div class="offcanvas offcanvas-start" tabindex="-1" id="createClient" aria-labelledby="createClientLabel">

        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="createClientLabel"><font-awesome-icon icon="fa-solid fa-wrench" /> Create Client
            </h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>

        <div class="offcanvas-body">

            <div class="row mb-3">
                <div class="col-12">
                    <div class="mb-2">
                        <label class="form-label" for="name">Name</label>
                        <input v-model="userSettings.name" class="form-control" type="text" name="name" />
                    </div>
                    <div class="mb-2">
                        <label class="form-label" for="email">Email</label>
                        <input v-model="userSettings.email" class="form-control" type="text" name="email" />
                    </div>
                    <div class="mb-2">
                        <label class="form-label" for="password">Password</label>
                        <input v-model="userSettings.password" class="form-control" type="password" name="password" />
                    </div>
                    <div class="mb-2">
                        <label class="form-label" for="company">Company</label>
                        <input v-model="userSettings.company" class="form-control" type="text" name="company" />
                    </div>
                    <div class="mb-2">
                        <label class="form-label" for="location">Location</label>
                        <input v-model="userSettings.location" class="form-control" type="text" name="location" placeholder="Enter UK or US" />
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <button class="btn btn-primary" type="button" v-on:click="createClient()">
                        <font-awesome-icon icon="fa-solid fa-floppy-disk" />
                        Create Client
                    </button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import vSelect from "vue-select";
import { createToast } from 'mosha-vue-toastify';

export default {
    components: {
        vSelect,
    },
    name: "CreateEditor",
    data() {
        return {

        }
    },
    setup() {

        var userSettings = ref({});

        return {
            userSettings
        }
    },
    methods: {
        createClient() {

            this.userSettings.userName = this.userSettings.email;

            axios.post('/api/user/createclient', this.userSettings)
                .then(response => {
                    console.log(response);

                    if (response.status === 200)
                    {
                        createToast('Client created', { position: 'bottom-right', type: 'success', showIcon: true });
                        var createClientCanvas = document.getElementById('createClient');
                        createClientCanvas.classList.remove('show');    
                    }
                    else {
                        createToast('Failed to create Client', { position: 'bottom-right', type: 'warning', showIcon: true });
                    }                    
                })
                .catch(error => {
                    console.log(error);
                });
        }
    },
}
</script>

<style scoped></style>