<template>
    <div class="modal fade" id="confirmPublish" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirm Publish to Clients</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>Are you sure? This will push this page of content to the clients.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" v-on:click="confirmPublish()">Publish</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'confirm-publish-component',
    methods: {
        confirmPublish() {
            this.emitter.emit('confirm-publish');
            $('#confirmPublish').modal('hide');
        }
    },
}
</script>