import '../scss/app.scss';

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import 'bootstrap';

import EditorDashboard from './vue/editorDashboard';
import ClientDashboard from './vue/clientDashboard';
import UserManagement from './vue/userManagement';

window.onload = () => {
    
    if (document.querySelector('.page__dashboard'))
    {
        new EditorDashboard();
        new ClientDashboard();
    }

    if (document.querySelector('.page__editor-dashboard'))
    {
        new EditorDashboard();
    }

    if (document.querySelector('.page__client-dashboard'))
    {
        new ClientDashboard();
    }

    if (document.querySelector('.page__users'))
    {
        new UserManagement();
    }

    /*
        Custom jquery-validation handling for check boxes must equal true
        Decorate C# model bool properties with
        [Range(typeof(bool), 'true', 'true', ErrorMessage = 'Add your error message')]
        to make their corresponding checkbox validate to 'must equal true'
    */
    var defaultRangeValidator = $.validator.methods.range;
    $.validator.methods.range = function (value, element, param) {
        if (element.type === 'checkbox') {
            // if it's a checkbox return true if it is checked
            return element.checked;
        } else {
            // otherwise run the default validation function
            return defaultRangeValidator.call(this, value, element, param);
        }
    }

    /* Prevent Enter key from submitting form */
    $('form input').on('keydown', function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            return false;
        }
    });

};