<template>
  <div class="offcanvas offcanvas-start" tabindex="-1" id="editorSettings" aria-labelledby="editorSettingsLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="editorSettingsLabel"><font-awesome-icon icon="fa-solid fa-wrench" /> Publishing
        Settings</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>

    <div class="offcanvas-body">

      <div class="row mb-3">
        <div class="col-12">
          <label class="form-label">Selected language</label>
          <vSelect v-model="selectedLanguage" :options="languageOptions" :clearable="false"></vSelect>
          <small id="languageHelp" class="form-text text-muted">The current language that you are creating social posts
            in.</small>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12">
          <label for="basic-url" class="form-label">Time range for post publication</label>
          <Datepicker v-model="selectedTimeRange" time-picker minutes-increment="5" range placeholder="Select Time"></Datepicker>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12">
          <button class="btn btn-primary" type="button" v-on:click="save()">
            <font-awesome-icon icon="fa-solid fa-floppy-disk" />
            Save Publish Times
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import vSelect from "vue-select";
import { createToast } from 'mosha-vue-toastify';

export default {
  components: {
    vSelect,
  },
  name: "EditorSettings",
  data() {
    return {
      userSettings: {
        exportToBuffer: true,
        exportToHootSuite: true,
        exportToHubSpot: true,
        exportToPubler: true,
        exportWordTemplates: true
      },
      selectedLanguage: {
        label: 'English (UK)',
        code: 'ac754742-5087-4e19-8c98-1198fcf244a3',
        selected: true,
      }
    }
  },
  setup() {

    let selectedTimeRange = ref();
    let languageOptions = ref();

    const getAvailableLanguages = async () => {
      const apiEndpoint = "/api/settings/getlanguages";
      let { data } = await axios({
        url: apiEndpoint,
        method: "GET"
      });

      languageOptions.value = data;
    };

    let userId = $('#UserId').val();

    const getSettings = async () => {
      let { data } = await axios({
        url: `/api/settings/getsettings?id=${userId}`,
        method: 'GET',
      });

      return data;
    };

    return {
      getAvailableLanguages,
      getSettings,
      userId,
      selectedTimeRange,
      languageOptions,
    }
  },
  async created() {
    await this.getAvailableLanguages();
    this.userSettings = await this.getSettings();

    let startTimeSplit = this.userSettings.postPublishStartTime.split(':');
    let endTimeSplit = this.userSettings.postPublishEndTime.split(':');

    this.selectedTimeRange = [
      {
        hours: startTimeSplit[0],
        minutes: startTimeSplit[1],
        seconds: startTimeSplit[2],
      },
      {
        hours: endTimeSplit[0],
        minutes: endTimeSplit[1],
        seconds: endTimeSplit[2],
      }
    ];
  },
  computed: {},
  watch: {
    selectedLanguage() {
      this.emitter.emit("change-language", this.selectedLanguage);
      $('#editorSettings').offcanvas('hide');
      createToast(`Language changed to ${this.selectedLanguage.label}`, { position: 'bottom-right', type: 'success', showIcon: true });
    }
  },
  methods: {
    save() {
      this.userSettings.postPublishStartTime = this.convertToTimespan(this.selectedTimeRange[0].hours, this.selectedTimeRange[0].minutes);
      this.userSettings.postPublishEndTime = this.convertToTimespan(this.selectedTimeRange[1].hours, this.selectedTimeRange[1].minutes);

      const apiEndpoint = '/api/settings/savesettings/'

      axios.post(apiEndpoint, this.userSettings)
        .then(r => {
          createToast('Publish Times saved', { position: 'bottom-right', type: 'success', showIcon: true });
        });
    },
    convertToTimespan(hours, minutes) {
      let strHours = hours.toString();
      let strMinutes = minutes.toString();

      if (strHours.length == 1) {
        strHours = "0" + strHours;
      }

      if (strMinutes.length == 1) {
        strMinutes = "0" + strMinutes;
      }

      return `${strHours}:${strMinutes}:00`;
    }
  },
}
</script>

<style scoped>

</style>