<template>
    <div id="ClientDashboard">

        <div class="row weekly-controls u-date mb-2 mt-2">
            <div class="col-6 col-lg-3">
                <Datepicker v-model="selectedDate" :clearable="false" week-picker :format="this.datepickerFormat">
                </Datepicker>
            </div>
            <div class="col-12 col-lg-9">
                <div class="control-btns">
                    <button class="btn btn-light" type="button" data-bs-toggle="offcanvas" data-bs-target="#mySettings"
                        aria-controls="mySettings">
                        <font-awesome-icon icon="fa-solid fa-wrench" />
                        Settings
                    </button>
                    <button class="btn btn-light" data-bs-toggle="modal" data-bs-target="#confirmClear">
                        <font-awesome-icon icon="fa-solid fa-trash-can" />
                        Clear All
                    </button>
                    <button class="btn btn-light" v-on:click="exportPosts()">
                        <font-awesome-icon icon="fa-solid fa-download" />
                        Download
                    </button>
                    <button id="RssPublish" :class="this.publishRssCss" v-on:click="publishRss()">
                        <font-awesome-icon icon="fa-solid fa-square-rss" />
                        Publish to RSS
                    </button>
                    <div class="help-text">
                        Need help? 👉 <a href="https://knowledge.mspmarketingedge.com/en/?q=social+media" target="_blank">Knowledge Base</a>
                    </div>
                </div>                
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <hr />
            </div>
        </div>

        <div class="dashboard-posts">
            <draggable v-model="socialPostData" @end="changePostPosition()" group="socialPosts" type="transition" handle=".handle" class="draggable-zone"
                ghost-class="ghost" item-key="id">
                <template #item="{ element }">
                    <div class="row">
                        <div class="drag-icon handle">
                            <img src="/img/icon-draggable.png" alt="Reorder Post" width="16" height="16" />
                        </div>
                        <div class="post-area">
                            <ClientDashboardPost :key="element.id" :id="element.id" :isBlogPost="element.isBlogPost"
                                :postDate="element.postDate" :parsedPostDate="element.parsedPostDate" :postMessage="element.message"
                                :twitterMessage="element.twitterVariant" :hashtags="element.hashtags" :edited="element.edited"
                                :linkUrl="element.linkUrl" :locked="element.locked" :confirmed="element.confirmed"
                                :dateFormat="this.datepickerFormat">
                            </ClientDashboardPost>
                        </div>                     
                    </div>  
                </template>
            </draggable>
        </div>

        <div class="row export-controls u-hidden">
            <div class="col-12 text-right">
                <button v-on:click="confirmClear()" class="btn btn-secondary">Clear</button>
                <button v-on:click="exportPosts()" class="btn btn-primary">Export</button>
                <div class="confirm-clear alert alert-danger u-hidden">
                    Are you sure that you want to clear down this post data?
                    <button v-on:click="cancelClear()" class="btn btn-secondary">No</button>
                    <button v-on:click="clearPosts()" class="btn btn-danger">Yes</button>
                </div>
            </div>
        </div>

        <MySettings></MySettings>

        <ConfirmClearModal></ConfirmClearModal>

    </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import vSelect from "vue-select";
import ConfirmClearModal from '../components/confirmClearModalComponent.vue';
import MySettings from "./mySettings.vue";
import ClientDashboardPost from "./clientDashboardPost.vue";
import { createToast } from 'mosha-vue-toastify';

export default {
    components: {
        vSelect,
        ConfirmClearModal,
        MySettings,
        ClientDashboardPost
    },
    name: "ClientDashboard",
    data() {
        return {
            socialPostData: [],
            postType: "",
            userLocation: "",
            updateRss: false,
            currentLanguage: "",
        }
    },
    setup() {

        let userId = $('#UserId').val();
        let selectedDate = ref();
        let selectedTimeRange = ref();

        const getUserLocation = async () => {
            let { data } = await axios({
                url: `/api/user/getlocation/?userId=${userId}`,
                method: "GET"
            });

            return data;
        };

        return {
            getUserLocation,
            selectedDate,
            selectedTimeRange,
            userId,
        }
    },
    async created() {
        this.userLocation = await this.getUserLocation();
        let firstDay = this.getMonday();
        let lastDay = this.getMonday().setDate(firstDay.getDate() + 6);
        this.selectedDate = [firstDay, lastDay];
        this.start();
    },
    mounted() {
        this.emitter.on("update-rss", notifyRssUpdate => {
            this.updateRss = notifyRssUpdate;
        });

        this.emitter.on("confirm-clear", () => {
            console.log('Detected confirm clear emission');
            this.clearPosts();
        });

        this.emitter.on("clear-post", () => {
            this.start();
        })

        this.emitter.on("save-settings", () => {
            console.log("Caught save settings emitter.");
            this.socialPostData = [];
            this.start();
        })

        if (this.userLocation == "UK") {
            this.currentLanguage = "ac754742-5087-4e19-8c98-1198fcf244a3";
        }
        else {
            this.currentLanguage = "8c721e69-59ba-4627-9e1b-cb5b323efaea";
        }
    },
    computed: {
        datepickerFormat() {
            if (this.userLocation == "UK") {
                return "dd/MM/yyyy";
            }
            else {
                return "MM/dd/yyyy";
            }
        },
        publishRssCss() {
            if (!this.updateRss) {
                return "btn btn-success";
            }
            else {
                return "btn btn-warning";
            }
        },
    },
    watch: {
        selectedDate: function () {
            this.start();
        },
    },
    methods: {
        getMonday: function () {
            let currentDate = new Date();
            let day = currentDate.getDay() || 7;
            if (day !== 1) {
                currentDate.setHours(-24 * (day - 1));
            }
            return currentDate;
        },
        start: function () {
            let year = this.selectedDate[0].getFullYear();
            let calcYear = new Date(this.selectedDate[0].getFullYear(), 0, 1);
            let totalDays = Math.floor((this.selectedDate[0] - calcYear) / (24 * 60 * 60 * 1000));
            let week = Math.ceil((this.selectedDate[0].getDay() + 1 + totalDays) / 7);

            const apiEndpoint = "/api/custompost/getposts/";

            var postData = {
                'year': year,
                'weekNumber': week,
                'userId': this.userId,
            };

            axios({
                url: apiEndpoint,
                method: "POST",
                data: postData
            }).then(response => {
                console.log(response);
                this.socialPostData = response.data;
                this.draggablePostList = this.socialPostData;
            });
        },
        clearPosts: function () {
            let year = this.selectedDate[0].getFullYear();
            let calcYear = new Date(this.selectedDate[0].getFullYear(), 0, 1);
            let totalDays = Math.floor((this.selectedDate[0] - calcYear) / (24 * 60 * 60 * 1000));
            let week = Math.ceil((this.selectedDate[0].getDay() + 1 + totalDays) / 7);

            let postData = {
                "year": year,
                "weekNumber": week,
                "userId": this.userId,
            };

            const apiEndpoint = "/api/custompost/clear/";

            axios({
                url: apiEndpoint,
                method: "POST",
                data: postData
            }).then(response => {
                this.socialPostData = response.data;
                this.start();
                createToast('Posts have been cleared', { position: 'bottom-right', type: 'success', showIcon: true });
            });
        },
        exportPosts: async function () {

            let year = this.selectedDate[0].getFullYear();
            let calcYear = new Date(this.selectedDate[0].getFullYear(), 0, 1);
            let totalDays = Math.floor((this.selectedDate[0] - calcYear) / (24 * 60 * 60 * 1000));
            let week = Math.ceil((this.selectedDate[0].getDay() + 1 + totalDays) / 7);

            let firstDateOfWeek = this.getDateOfISOWeek(week, year);

            let postData = {
                "year": year,
                "weekNumber": week,
                "language": this.currentLanguage,
                "userId": this.userId,
            };

            let filename = `wc ${firstDateOfWeek.getMonth() + 1}-${firstDateOfWeek.getDate()}-${firstDateOfWeek.getUTCFullYear()}`;

            if (this.userLocation == "UK") {
                filename = `wc ${firstDateOfWeek.getDate()}-${firstDateOfWeek.getMonth() + 1}-${firstDateOfWeek.getUTCFullYear()}`;
            };

            const apiEndpoint = "/api/export/customposts/";

            const resp = await axios({
                url: apiEndpoint,
                method: 'POST',
                data: postData,
                responseType: 'blob'
            });

            this.downloadFile(filename, resp.data);
        },
        downloadFile: function (filename, data) {
            let binaryData = [];
            binaryData.push(data);

            var fileUrl = window.URL.createObjectURL(new Blob(binaryData, { type: "application/zip" }))

            const element = document.createElement("a");
            element.setAttribute("href", fileUrl);
            element.setAttribute("download", `${filename}.zip`);

            element.style.display = "none";
            element.click();
        },
        publishRss: async function () {
            const elem = document.getElementById("RssPublish");
            elem.disabled = true;
            
            const apiEndpoint = "/api/rss/publish/";

            const resp = await axios({
                url: apiEndpoint,
                method: 'POST',
                data: this.socialPostData,
            });

            if (resp.status === 200) {
                createToast('RSS feed has been published', { position: 'bottom-right', type: 'success', showIcon: true });
                this.updateRss = false;
            }
            else {
                createToast('RSS feed failed to publish', { position: 'bottom-right', type: 'error', showIcon: true });
            }

            elem.disabled = false;
        },
        getDateOfISOWeek(week, year) {
            var simple = new Date(year, 0, 1 + (week - 1) * 7);
            var dayOfWeek = simple.getDay();
            var ISOweekStart = simple;

            if (dayOfWeek <= 4) {
                ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
            }
            else {
                ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
            }

            return ISOweekStart;
        },
        changePostPosition: async function() {
            let i = 0;
            const apiEndpoint = "/api/custompost/updatepostdate/";

            this.socialPostData.forEach(element => {
                let firstPostDate = new Date(this.selectedDate[0]);
                let currentPostDate = new Date(element.postDate);

                switch (i)
                {
                    case 0:
                        let monPostDate = this.addDays(firstPostDate, 0);

                        if (currentPostDate.getDate() !== monPostDate.getDate())
                        {
                            element.postDate = new Date(Date.UTC(monPostDate.getFullYear(), monPostDate.getMonth(), monPostDate.getDate()));
                            element.parsedPostDate = `Monday ${monPostDate.getDate()}/${monPostDate.getMonth() + 1}/${monPostDate.getFullYear()}`;
                            
                            axios({
                                url: apiEndpoint,
                                method: "POST",
                                data: {
                                    'id': element.id,
                                    'postDate': element.postDate,
                                    'locked': false,
                                }
                            });

                            this.emitter.emit(element.id);
                        }
                        
                        break;
                    case 1:
                        let tuesPostDate = this.addDays(firstPostDate, 1);

                        if (currentPostDate.getDate() !== tuesPostDate.getDate())
                        {
                            element.postDate = new Date(Date.UTC(tuesPostDate.getFullYear(), tuesPostDate.getMonth(), tuesPostDate.getDate()));
                            element.parsedPostDate = `Tuesday ${tuesPostDate.getDate()}/${tuesPostDate.getMonth() + 1}/${tuesPostDate.getFullYear()}`;
                            
                            axios({
                                url: apiEndpoint,
                                method: "POST",
                                data: {
                                    'id': element.id,
                                    'postDate': element.postDate,
                                    'locked': false,
                                }
                            });
                            
                            this.emitter.emit(element.id);
                        }

                        break;
                    case 2:
                        let wedsPostDate = this.addDays(firstPostDate, 2);

                        if (currentPostDate.getDate() !== wedsPostDate.getDate())
                        {
                            element.postDate = new Date(Date.UTC(wedsPostDate.getFullYear(), wedsPostDate.getMonth(), wedsPostDate.getDate()));
                            element.parsedPostDate = `Wednesday ${wedsPostDate.getDate()}/${wedsPostDate.getMonth() + 1}/${wedsPostDate.getFullYear()}`;
                            
                            axios({
                                url: apiEndpoint,
                                method: "POST",
                                data: {
                                    'id': element.id,
                                    'postDate': element.postDate,
                                    'locked': false,
                                }
                            });

                            this.emitter.emit(element.id);
                        }

                        break;
                    case 3:
                        let thursPostDate = this.addDays(firstPostDate, 3);

                        if (currentPostDate.getDate() !== thursPostDate.getDate())
                        {
                            element.postDate = new Date(Date.UTC(thursPostDate.getFullYear(), thursPostDate.getMonth(), thursPostDate.getDate()));
                            element.parsedPostDate = `Thursday ${thursPostDate.getDate()}/${thursPostDate.getMonth() + 1}/${thursPostDate.getFullYear()}`;
                            
                            axios({
                                url: apiEndpoint,
                                method: "POST",
                                data: {
                                    'id': element.id,
                                    'postDate': element.postDate,
                                    'locked': false,
                                }
                            });

                            this.emitter.emit(element.id);
                        }
                       
                        break;
                    case 4:
                        let friPostDate = this.addDays(firstPostDate, 4);

                        if (currentPostDate.getDate() !== friPostDate.getDate())
                        {
                            element.postDate = new Date(Date.UTC(friPostDate.getFullYear(), friPostDate.getMonth(), friPostDate.getDate()));
                            element.parsedPostDate = `Friday ${friPostDate.getDate()}/${friPostDate.getMonth() + 1}/${friPostDate.getFullYear()}`;
                            
                            axios({
                                url: apiEndpoint,
                                method: "POST",
                                data: {
                                    'id': element.id,
                                    'postDate': element.postDate,
                                    'locked': false,
                                }
                            });

                            this.emitter.emit(element.id);
                        }
                        
                        break;
                    case 5:
                        let satPostDate = this.addDays(firstPostDate, 5);

                        if (currentPostDate.getDate() !== satPostDate.getDate())
                        {
                            element.postDate = new Date(Date.UTC(satPostDate.getFullYear(), satPostDate.getMonth(), satPostDate.getDate()));
                            element.parsedPostDate = `Saturday ${satPostDate.getDate()}/${satPostDate.getMonth() + 1}/${satPostDate.getFullYear()}`;
                           
                            axios({
                                url: apiEndpoint,
                                method: "POST",
                                data: {
                                    'id': element.id,
                                    'postDate': element.postDate,
                                    'locked': false,
                                }
                            });

                            this.emitter.emit(element.id);
                        }
                       
                        break;
                    case 6:
                        let sunPostDate = this.addDays(firstPostDate, 6);

                        if (currentPostDate.getDate() !== sunPostDate.getDate())
                        {
                            element.postDate = new Date(Date.UTC(sunPostDate.getFullYear(), sunPostDate.getMonth(), sunPostDate.getDate()));
                            element.parsedPostDate = `Sunday ${sunPostDate.getDate()}/${sunPostDate.getMonth() + 1}/${sunPostDate.getFullYear()}`;
                            
                            axios({
                                url: apiEndpoint,
                                method: "POST",
                                data: {
                                    'id': element.id,
                                    'postDate': element.postDate,
                                    'locked': false,
                                }
                            });
                            
                            this.emitter.emit(element.id);
                        }
                        
                        break; 
                }

                ++i;                
            });
        },
        addDays: function(date, days) { // expects a date object and a number of days to add
            date.setDate(date.getDate() + days);
            return date;
        },
    },
}
</script>

<style scoped>
.draggable-zone {
    padding: 10px;
    margin: 0 0 20px 0;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>