import { createApp } from "vue";

/* import acios */
import axios from 'axios';
import VueAxios from 'vue-axios';

/* import emitter bus */
import mitt from 'mitt';

/* import vSelect */
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';

/* import DatePicker */
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

/* import the toastr */
import moshaToast from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

import draggable from 'vuedraggable';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import { faDownload, faCloudUpload, faTrashCan, faFloppyDisk, faCircleChevronRight, faWrench, faRssSquare, faLock, faLockOpen, faGripHorizontal} from '@fortawesome/free-solid-svg-icons';

/* add icons to the library */
library.add(faDownload, faCloudUpload, faTrashCan, faFloppyDisk, faCircleChevronRight, faWrench, faRssSquare, faLock, faLockOpen, faGripHorizontal);

import ClientDashboardTemplate from '../../vue/templates/clientDashboard.vue';

export default class ClientDashboard {
    constructor() {
        const emitter = mitt();

        const app = createApp(ClientDashboardTemplate)
            .component('Datepicker', Datepicker)
            .component('font-awesome-icon', FontAwesomeIcon)
            .component('draggable', draggable);

        app.config.globalProperties.emitter = emitter;

        app.use(VueAxios, axios, moshaToast, vSelect);
        app.mount('#client-dashboard-component');
    }
}