<template>
    <div class="workflow-section">
        <div class="workflow-container">
            <div class="workflow-header mb-3">
                <h5>Internal Tasks</h5>
            </div>
            <div class="workflow-steps">
                <div class="mb-2">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" :value="workflowSteps.step1.id" id="step1check"
                            v-on:click="setWorkflowStep1()">
                        <label class="form-check-label" for="step1check">
                            {{ workflowSteps.step1.name }}
                        </label>
                    </div>
                </div>
                <div class="mb-2">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" :value="workflowSteps.step2.id" id="step2check"
                            v-on:click="setWorkflowStep2()">
                        <label class="form-check-label" for="step2check">
                            {{ workflowSteps.step2.name }}
                        </label>
                    </div>
                </div>
                <div class="mb-2">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" :value="workflowSteps.step3.id" id="step3check"
                            v-on:click="setWorkflowStep3()">
                        <label class="form-check-label" for="step3check">
                            {{ workflowSteps.step3.name }}
                        </label>
                    </div>
                </div>
                <div class="mb-2">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" :value="workflowSteps.step4.id" id="step4check"
                            v-on:click="setWorkflowStep4()">
                        <label class="form-check-label" for="step4check">
                            {{ workflowSteps.step4.name }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { createToast } from 'mosha-vue-toastify';

export default {
    name: 'workflow-component',
    props: [
        "week",
        "year",
    ],
    data() {
        return {
            currentWorkflowSteps: "",
            workflowSteps: {
                step1: {
                    id: '9b5d04d0-87e1-4b2b-a081-9570b7105c28',
                    name: 'Research added',
                    checked: false,
                },
                step2: {
                    id: 'a6c214f0-d85c-4859-8d5c-a2ada4237978',
                    name: 'Content written',
                    checked: false,
                },
                step3: {
                    id: '92dac660-a721-4722-a46c-b99deec98b77',
                    name: 'Editor checked',
                    checked: false,
                },
                step4: {
                    id: 'c4514d26-8302-405a-bcd1-e0a120ce08ce',
                    name: 'Checked & published',
                    checked: false,
                },
            },
            languageId: '8c721e69-59ba-4627-9e1b-cb5b323efaea',
        }
    },
    mounted() {
        this.emitter.on("change-language", lang => {
            this.languageId = lang.code;
            this.start();
        });

       this.start(); 
    }, 
    watch: {
        week: function () {
            this.start();
        },
    },
    methods: {
        start: async function () {

            this.currentWorkflowSteps == null;
            document.getElementById("step1check").checked = false;
            document.getElementById("step2check").checked = false;
            document.getElementById("step3check").checked = false;
            document.getElementById("step4check").checked = false;

            const getCurrentWorkflowStates = async () => {
                let { data } = await axios({
                    url: `/api/workflow/getworkflowstate/?week=${this.week}&year=${this.year}&languageId=${this.languageId}`,
                    method: "GET"
                });

                return data;
            };

            this.currentWorkflowSteps = await getCurrentWorkflowStates();
            
            if (!this.currentWorkflowSteps)
            {
                return;
            }

            this.currentWorkflowSteps.forEach(step => {
                if (step == this.workflowSteps.step1.id) {
                    document.getElementById("step1check").checked = true;
                }
                if (step == this.workflowSteps.step2.id) {
                    document.getElementById("step2check").checked = true;
                }
                if (step == this.workflowSteps.step3.id) {
                    document.getElementById("step3check").checked = true;
                }
                if (step == this.workflowSteps.step4.id) {
                    document.getElementById("step4check").checked = true;
                }
            });
        },
        setWorkflowStep1: function () {
            const apiEndpoint = "/api/workflow/updateworkflowstep/";

            let postData = {
                'year': this.year,
                'week': this.week,
                'workflowStepId': this.workflowSteps.step1.id,
                'languageId': this.languageId,
            };

            axios.post(apiEndpoint, postData)
                .then(response => {
                    console.log(response);
                    createToast('Workflow Saved', { position: 'bottom-right', type: 'success', showIcon: true });
                })
                .catch(error => {
                    console.log(error);
                    createToast('Failed to save Workflow', { position: 'bottom-right', type: 'danger', showIcon: true });
                });
        },
        setWorkflowStep2: function () {
            const apiEndpoint = "/api/workflow/updateworkflowstep/";

            let postData = {
                'year': this.year,
                'week': this.week,
                'workflowStepId': this.workflowSteps.step2.id,
                'languageId': this.languageId,
            };

            axios.post(apiEndpoint, postData)
                .then(response => {
                    console.log(response);
                    createToast('Workflow Saved', { position: 'bottom-right', type: 'success', showIcon: true });
                })
                .catch(error => {
                    console.log(error);
                    createToast('Failed to save Workflow', { position: 'bottom-right', type: 'danger', showIcon: true });
                });
        },
        setWorkflowStep3: function () {
            const apiEndpoint = "/api/workflow/updateworkflowstep/";

            let postData = {
                'year': this.year,
                'week': this.week,
                'workflowStepId': this.workflowSteps.step3.id,
                'languageId': this.languageId,
            };

            axios.post(apiEndpoint, postData)
                .then(response => {
                    console.log(response);
                    createToast('Workflow Saved', { position: 'bottom-right', type: 'success', showIcon: true });
                })
                .catch(error => {
                    console.log(error);
                    createToast('Failed to save Workflow', { position: 'bottom-right', type: 'danger', showIcon: true });
                });
        },
        setWorkflowStep4: function () {
            const apiEndpoint = "/api/workflow/updateworkflowstep/";

            let postData = {
                'year': this.year,
                'week': this.week,
                'workflowStepId': this.workflowSteps.step4.id,
                'languageId': this.languageId,
            };

            axios.post(apiEndpoint, postData)
                .then(response => {
                    console.log(response);
                    createToast('Workflow Saved', { position: 'bottom-right', type: 'success', showIcon: true });
                })
                .catch(error => {
                    console.log(error);
                    createToast('Failed to save Workflow', { position: 'bottom-right', type: 'danger', showIcon: true });
                });
        },
    },
}
</script>