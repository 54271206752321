<template>
    <div id="EditorDashboard">

        <loading v-model:active='isLoading'
                 :can-cancel=false
                 :enforce-focus=true
                 :height='128'
                 :width='128'
                 :is-full-page="fullPage"/>

        <div class="row">
            <div class="col-12">
                <img :src="flagUrl" class="language-flag" width="50" height="50" alt="flag" /><span class="language-heading">{{ currentLanguage.label }}</span>
            </div>
        </div>

        <div class="row weekly-controls u-date mb-2 mt-2">
            <div class="col-6 col-lg-3">
                <Datepicker v-model="selectedDate" :clearable="false" week-picker format="dd/MM/yyyy"></Datepicker>
            </div>
            <div class="col-12 col-lg-9">
                <div class="control-btns">
                    <button class="btn btn-light" type="button" data-bs-toggle="offcanvas" data-bs-target="#editorSettings"
                        aria-controls="editorSettings">
                        <font-awesome-icon icon="fa-solid fa-wrench" />
                        Settings
                    </button>
                    <button class="btn btn-light" data-bs-toggle="modal" data-bs-target="#confirmClear">
                        <font-awesome-icon icon="fa-solid fa-trash-can" />
                        Clear All
                    </button>
                    <button class="btn btn-light" v-on:click="createLanguageVariants()" v-if="displayVariantButton()">
                        <font-awesome-icon icon="fa-solid fa-earth-europe" />
                        Create US Variants
                    </button>
                    <button class="btn btn-light" v-on:click="exportFiles()">
                        <font-awesome-icon icon="fa-solid fa-download" />
                        Download
                    </button>
                    <button class="btn btn-light" data-bs-toggle="modal" data-bs-target="#confirmPublish">
                        <font-awesome-icon icon="fa-solid fa-cloud-upload" />
                        Publish to Clients
                    </button>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <hr />
            </div>
        </div>

        <div class="dashboard-posts">
            <draggable v-model="socialPostData" @end="changePostPosition()" group="socialPosts" type="transition"
                handle=".handle" class="draggable-zone" ghost-class="ghost" item-key="id">
                <template #item="{ element }">
                    <div class="row">
                        <div class="drag-icon handle">
                            <img src="/img/icon-draggable.png" alt="Reorder Post" width="16" height="16" />
                        </div>
                        <div class="post-area">
                            <EditorDashboardPost :key="element.id"
                                :id="element.id" :language="this.currentLanguage.code" :postDate="element.postDate"
                                :parsedPostDate="element.parsedPostDate" :postMessage="element.message"
                                :twitterMessage="element.twitterVariant" :hashtags="element.hashtags"
                                :edited="element.edited" :linkUrl="element.linkUrl" :completed="element.completed">
                            </EditorDashboardPost>
                        </div>
                    </div>
                </template>
            </draggable>
        </div>

        <BlogExcerptComponent :key="this.blogExcerpt.id"
            :id="this.blogExcerpt.id" :language="this.currentLanguage.code" :hashtags="this.blogExcerpt.hashTags"
            :excerpt="this.blogExcerpt.excerpt" :shortExcerpt="this.blogExcerpt.shortExcerpt" :edited="this.blogExcerpt.edited"
            :weekNumber="this.weekNumber" :year="this.year" >
        </BlogExcerptComponent>
        
        <EditorSettings></EditorSettings>

        <ConfirmClearModal></ConfirmClearModal>

        <ConfirmPublishModal></ConfirmPublishModal>

        <Workflow :week="currentWeek" :year="currentYear"></Workflow>

    </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import vSelect from "vue-select";
import EditorSettings from "./editorSettings.vue";
import ConfirmClearModal from '../components/confirmClearModalComponent.vue';
import ConfirmPublishModal from '../components/confirmPublishModalComponent.vue';
import BlogExcerptComponent from '../components/blogExcerptComponent.vue';
import EditorDashboardPost from "./editorDashboardPost.vue";
import Workflow from "../components/workflowComponent.vue";
import { createToast } from 'mosha-vue-toastify';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
    components: {
        vSelect,
        EditorSettings,
        EditorDashboardPost,
        ConfirmClearModal,
        ConfirmPublishModal,
        BlogExcerptComponent,
        Workflow,
        Loading,
    },
    name: "EditorDashboard",
    data() {
        return {
            socialPostData: [],
            postType: "",
            currentLanguage: {
                label: 'English (UK)',
                code: 'ac754742-5087-4e19-8c98-1198fcf244a3',
            },
            flagUrl: '/img/flags/flag-uk.jpg',
            blogExcerpt: {
                id: '00000000-0000-0000-0000-000000000000',
                hashtags: '',
                excerpt: '',
                shortExcerpt: '',
                edited: false,
            },
            weekNumber: 0,
            isLoading: false,
        }
    },
    setup() {

        let selectedDate = ref();
        let selectedTimeRange = ref();
        let year = ref();

        return {
            selectedDate,
            selectedTimeRange,
            year,
        }
    },
    async created() {

        let firstDay = this.getMonday();
        let lastDay = this.getMonday().setDate(firstDay.getDate() + 6);
        this.selectedDate = [firstDay, lastDay];
        this.year = this.selectedDate[0].getFullYear();

        this.start();
    },
    mounted() {
        this.emitter.on("change-language", lang => {
            this.currentLanguage = lang;
            this.start();
        });

        this.emitter.on("clear-post", () => {
            this.start();
        })

        this.emitter.on("confirm-clear", () => {
            console.log('Detected confirm clear emission');
            this.clearPosts();
        });

        this.emitter.on('confirm-publish', () => {
            console.log('Detected confirm publish emission');
            this.publish();
        });

        this.emitter.on('blog-id', blog => {
            this.blogExcerpt = blog;
            this.blogExcerpt.edited = true
        });
    },
    computed: {
        currentWeek: function () {
            let calcYear = new Date(this.selectedDate[0].getFullYear(), 0, 1);
            let totalDays = Math.floor((this.selectedDate[0] - calcYear) / (24 * 60 * 60 * 1000));
            return Math.ceil((this.selectedDate[0].getDay() + 1 + totalDays) / 7);
        },
        currentYear: function () {
            return this.selectedDate[0].getFullYear();
        },
    },
    watch: {
        selectedDate: function () {
            this.start();
        },
        currentLanguage: function() {
            if (this.currentLanguage.code == '8c721e69-59ba-4627-9e1b-cb5b323efaea')
            {
                this.flagUrl = '/img/flags/flag-us.jpg';
            }
            else {
                this.flagUrl = '/img/flags/flag-uk.jpg';
            }
        }
    },
    methods: {
        getMonday: function () {
            let currentDate = new Date();
            let day = currentDate.getDay() || 7;
            if (day !== 1) {
                currentDate.setHours(-24 * (day - 1));
            }
            return currentDate;
        },
        start: function () {

            this.socialPostData = [];

            const apiEndpoint = "/api/socialpost/get/";

            this.year = this.selectedDate[0].getFullYear();
            let calcYear = new Date(this.selectedDate[0].getFullYear(), 0, 1);
            let totalDays = Math.floor((this.selectedDate[0] - calcYear) / (24 * 60 * 60 * 1000));
            this.weekNumber = Math.ceil((this.selectedDate[0].getDay() + 1 + totalDays) / 7);

            let postData = {
                "year": this.year,
                "weekNumber": this.weekNumber,
                "language": this.currentLanguage.code,
            };

            axios({
                url: apiEndpoint,
                method: "POST",
                data: postData
            }).then(response => {
                this.socialPostData = response.data;
            });

            const blogApiEndpoint = `/api/blog/getexcerpt/?weekNumber=${this.weekNumber}&year=${this.year}&language=${this.currentLanguage.code}`;

            axios({
                url: blogApiEndpoint,
                method: "GET",
            }).then(response => {
                this.blogExcerpt = response.data;
            });
        },
        clearPosts: async function () {
            let year = this.selectedDate[0].getFullYear();
            let calcYear = new Date(this.selectedDate[0].getFullYear(), 0, 1);
            let totalDays = Math.floor((this.selectedDate[0] - calcYear) / (24 * 60 * 60 * 1000));
            let week = Math.ceil((this.selectedDate[0].getDay() + 1 + totalDays) / 7);

            let postData = {
                "year": year,
                "weekNumber": week,
                "language": this.currentLanguage.code,
            };

            const apiEndpoint = "/api/socialpost/clear/";

            const postResponse = await axios({
                url: apiEndpoint,
                method: "POST",
                data: postData
            });

            this.socialPostData = postResponse.data;

            const blogApiEndpoint = `/api/blog/deleteexcerpt/?id=${this.blogExcerpt.id}`;

            await axios({
                url: blogApiEndpoint,
                method: "POST"
            });

            this.start();
            createToast('Posts have been cleared', { position: 'bottom-right', type: 'success', showIcon: true });
        },
        exportFiles: async function () {

            let year = this.selectedDate[0].getFullYear();
            let calcYear = new Date(this.selectedDate[0].getFullYear(), 0, 1);
            let totalDays = Math.floor((this.selectedDate[0] - calcYear) / (24 * 60 * 60 * 1000));
            let week = Math.ceil((this.selectedDate[0].getDay() + 1 + totalDays) / 7);

            let firstDateOfWeek = this.getDateOfISOWeek(week, year);

            let postData = {
                "year": year,
                "weekNumber": week,
                "language": this.currentLanguage.code,
            };

            let filename = `US wc ${firstDateOfWeek.getMonth() + 1}-${firstDateOfWeek.getDate()}-${firstDateOfWeek.getUTCFullYear()}`;

            if (this.currentLanguage.code == 'ac754742-5087-4e19-8c98-1198fcf244a3') {
                filename = `UK wc ${firstDateOfWeek.getDate()}-${firstDateOfWeek.getMonth() + 1}-${firstDateOfWeek.getUTCFullYear()}`;
            };

            const apiEndpoint = "/api/export/socialposts/";

            const resp = await axios({
                url: apiEndpoint,
                method: 'POST',
                data: postData,
                responseType: 'blob'
            });

            this.downloadFile(filename, resp.data);
        },
        downloadFile: function (filename, data) {
            let binaryData = [];
            binaryData.push(data);

            var fileUrl = window.URL.createObjectURL(new Blob(binaryData, { type: "application/zip" }))

            const element = document.createElement("a");
            element.setAttribute("href", fileUrl);
            element.setAttribute("download", `${filename}.zip`);

            element.style.display = "none";
            element.click();
        },
        publish: function () {

            const apiEndpoint = "/api/socialpost/publish/";

            let year = this.selectedDate[0].getFullYear();
            let calcYear = new Date(this.selectedDate[0].getFullYear(), 0, 1);
            let totalDays = Math.floor((this.selectedDate[0] - calcYear) / (24 * 60 * 60 * 1000));
            let week = Math.ceil((this.selectedDate[0].getDay() + 1 + totalDays) / 7);

            let publishDto = {
                "weekNumber": week,
                "year": year,
                "languageId": this.currentLanguage.code,
            }

            this.isLoading = true;

            axios({
                url: apiEndpoint,
                method: "POST",
                data: publishDto
            }).then(response => {
                this.isLoading = false;
                console.log(response);
                createToast('Posts have been published', { position: 'bottom-right', type: 'success', showIcon: true });
            });
        },
        createLanguageVariants: function () {
            const apiEndpoint = "/api/socialpost/createlanguagevariant/";

            let year = this.selectedDate[0].getFullYear();
            let calcYear = new Date(this.selectedDate[0].getFullYear(), 0, 1);
            let totalDays = Math.floor((this.selectedDate[0] - calcYear) / (24 * 60 * 60 * 1000));
            let week = Math.ceil((this.selectedDate[0].getDay() + 1 + totalDays) / 7);

            let variantDto = {
                'week': week,
                'year': year,
                'language': '8c721e69-59ba-4627-9e1b-cb5b323efaea'
            }

            axios({
                url: apiEndpoint,
                method: "POST",
                data: variantDto
            }).then(response => {
                createToast('Language variants created', { position: 'bottom-right', type: 'success', showIcon: true });
            });
        },
        displayVariantButton() {
            return this.currentLanguage.code === 'ac754742-5087-4e19-8c98-1198fcf244a3';
        },
        getDateOfISOWeek(week, year) {
            var simple = new Date(year, 0, 1 + (week - 1) * 7);
            var dayOfWeek = simple.getDay();
            var ISOweekStart = simple;

            if (dayOfWeek <= 4) {
                ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
            }
            else {
                ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
            }

            return ISOweekStart;
        },
        changePostPosition: async function() {
            let i = 0;

            const apiEndpoint = "/api/socialpost/updatepostdates/";

            this.socialPostData.forEach(element => {
                let firstPostDate = new Date(this.selectedDate[0]);
                let currentPostDate = new Date(element.postDate);

                switch (i) {
                    case 0:
                        let monPostDate = this.addDays(firstPostDate, 0);

                        if (currentPostDate.getDate() !== monPostDate.getDate()) {
                            element.postDate = new Date(Date.UTC(monPostDate.getFullYear(), monPostDate.getMonth(), monPostDate.getDate()));
                            element.parsedPostDate = `Monday ${monPostDate.getDate()}/${monPostDate.getMonth() + 1}/${monPostDate.getFullYear()}`;
                            this.emitter.emit(element.id);
                        }

                        break;
                    case 1:
                        let tuesPostDate = this.addDays(firstPostDate, 1);

                        if (currentPostDate.getDate() !== tuesPostDate.getDate()) {
                            element.postDate = new Date(Date.UTC(tuesPostDate.getFullYear(), tuesPostDate.getMonth(), tuesPostDate.getDate()));
                            element.parsedPostDate = `Tuesday ${tuesPostDate.getDate()}/${tuesPostDate.getMonth() + 1}/${tuesPostDate.getFullYear()}`;
                            this.emitter.emit(element.id);
                        }

                        break;
                    case 2:
                        let wedsPostDate = this.addDays(firstPostDate, 2);

                        if (currentPostDate.getDate() !== wedsPostDate.getDate()) {
                            element.postDate = new Date(Date.UTC(wedsPostDate.getFullYear(), wedsPostDate.getMonth(), wedsPostDate.getDate()));
                            element.parsedPostDate = `Wednesday ${wedsPostDate.getDate()}/${wedsPostDate.getMonth() + 1}/${wedsPostDate.getFullYear()}`;
                            this.emitter.emit(element.id);
                        }

                        break;
                    case 3:
                        let thursPostDate = this.addDays(firstPostDate, 3);

                        if (currentPostDate.getDate() !== thursPostDate.getDate()) {
                            element.postDate = new Date(Date.UTC(thursPostDate.getFullYear(), thursPostDate.getMonth(), thursPostDate.getDate()));
                            element.parsedPostDate = `Thursday ${thursPostDate.getDate()}/${thursPostDate.getMonth() + 1}/${thursPostDate.getFullYear()}`;
                            this.emitter.emit(element.id);
                        }

                        break;
                    case 4:
                        let friPostDate = this.addDays(firstPostDate, 4);

                        if (currentPostDate.getDate() !== friPostDate.getDate()) {
                            element.postDate = new Date(Date.UTC(friPostDate.getFullYear(), friPostDate.getMonth(), friPostDate.getDate()));
                            element.parsedPostDate = `Friday ${friPostDate.getDate()}/${friPostDate.getMonth() + 1}/${friPostDate.getFullYear()}`;
                            this.emitter.emit(element.id);
                        }

                        break;
                    case 5:
                        let satPostDate = this.addDays(firstPostDate, 5);

                        if (currentPostDate.getDate() !== satPostDate.getDate()) {
                            element.postDate = new Date(Date.UTC(satPostDate.getFullYear(), satPostDate.getMonth(), satPostDate.getDate()));
                            element.parsedPostDate = `Saturday ${satPostDate.getDate()}/${satPostDate.getMonth() + 1}/${satPostDate.getFullYear()}`;
                            this.emitter.emit(element.id);
                        }

                        break;
                    case 6:
                        let sunPostDate = this.addDays(firstPostDate, 6);
                        if (currentPostDate.getDate() !== sunPostDate.getDate()) {
                            element.postDate = new Date(Date.UTC(sunPostDate.getFullYear(), sunPostDate.getMonth(), sunPostDate.getDate()));
                            element.parsedPostDate = `Sunday ${sunPostDate.getDate()}/${sunPostDate.getMonth() + 1}/${sunPostDate.getFullYear()}`;
                            this.emitter.emit(element.id);
                        }

                        break;
                }

                ++i;
            });

            const resp = await axios({
                url: apiEndpoint,
                method: 'POST',
                data: this.socialPostData,
            });

            this.start();
        },
        addDays: function (date, days) { // expects a date object and a number of days to add
            date.setDate(date.getDate() + days);
            return date;
        },
    },
}

</script>

<style scoped>
.draggable-zone {
    padding: 10px;
    margin: 0 0 20px 0;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>