<template>
    <div class="offcanvas offcanvas-start" tabindex="-1" id="mySettings" aria-labelledby="mySettingsLabel">

        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="emySettingsLabel"><font-awesome-icon icon="fa-solid fa-wrench" /> My
                Settings</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>

        <div class="offcanvas-body">

            <div class="row mb-2">
                <div class="col-12">
                    <button class="btn btn-primary" type="button" v-on:click="save()">
                        <font-awesome-icon icon="fa-solid fa-floppy-disk" />
                        Save Settings
                    </button>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-12">
                  <hr />
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-12">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="userSettings.enableAutomatedPublishing" id="EnableAutomatedPublishing">
                        <label class="form-check-label" for="EnableAutomatedPublishing">
                            Fully Automated Publishing
                        </label>
                    </div>
                    <div class="mb-2">
                        <small id="automatedPublishingHelp" class="form-text text-muted">When you tick this box, MSP Marketing Edge content will be automatically published to 
                            your social media platforms every week. Remember, you retain ultimate responsibilty for what is published. We recommend you regularly read the 
                            content.</small>
                    </div>                    
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-12">
                  <hr />
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-12">
                    <label for="basic-url" class="form-label">Time range for post publication</label>
                    <Datepicker v-model="selectedTimeRange" time-picker minutes-increment="5" range placeholder="Select Time"></Datepicker>
                    <small class="form-text text-muted">Each post is published at a time picked randomly. You can choose the time range. We suggest picking a range your audience 
                        could be most active on social media. Try mid-morning, lunchtime, mid-afternoon or evening.</small>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-12">
                    <label class="form-label">Selected Time Zone</label>
                    <vSelect v-model="selectedTimeZone" :options="timezoneOptions" :clearable="false"></vSelect>
                    <small id="languageHelp" class="form-text text-muted">Your current time zone for publishing
                        times</small>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-12">
                  <hr />
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-12">
                    <div class="form-check mb-2">
                        <input class="form-check-input" type="checkbox" value="" v-model="userSettings.enableBlogContent" id="EnableBlogContent" >
                        <label class="form-check-label" for="EnableBlogContent">
                            Replace Content To Promote Your Blog Post
                        </label>
                    </div>
                    <div class="mb-3">
                        <small id="blogPublishHelp" class="form-text text-muted">In our Weekly Marketing System we recommend you publish the blog and use 
                            social media to drive traffic to it. If you have our AutoBlog plugin installed on a WordPress site, your blog will be published 
                            for you every week, and the URL automatically added into SocialTools. That means 100% of your blog and social media will be 
                            automated. No work for you 😃
                            <br />
                            If you can't use AutoBlog, please manually paste the blog URL into your SocialTools schedule every week. You can also choose below 
                            which day's social media will be swapped out for this post (to fit in with your weekly marketing systems).</small>
                    </div>
                    <div id="selectBlogPublishDay">
                        <label class="form-label">Which day to replace with blog post</label>
                        <vSelect id="SelectedBlogDay" v-model="selectedBlogDay" :options="blogPublishOptions" :clearable="false" :disabled="!this.userSettings.enableBlogContent"></vSelect>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                  <hr />
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-12">
                    <h5>Download files</h5>
                    <div class="form-check form-switch mb-2">
                        <input v-model="userSettings.exportToBuffer" class="form-check-input" type="checkbox"
                            id="ExportToBuffer">
                        <label class="form-check-label" for="ExportToBuffer">Buffer</label>
                    </div>
                    <div class="form-check form-switch mb-2">
                        <input v-model="userSettings.exportToPubler" class="form-check-input" type="checkbox"
                            id="ExportToPubler">
                        <label class="form-check-label" for="ExportToPubler">Publer</label>
                    </div>
                    <div class="form-check form-switch mb-2">
                        <input v-model="userSettings.exportToHootSuite" class="form-check-input" type="checkbox"
                            id="ExportToHootSuite">
                        <label class="form-check-label" for="ExportToHootSuite">HootSuite</label>
                    </div>
                    <div class="form-check form-switch mb-2">
                        <input v-model="userSettings.exportToGrowably" class="form-check-input" type="checkbox"
                            id="ExportToGrowably">
                        <label class="form-check-label" for="ExportToGrowably">Growably</label>
                    </div>
                    <div class="form-check form-switch mb-2">
                        <input v-model="userSettings.exportToHubSpot" class="form-check-input" type="checkbox"
                            id="ExportToHubSpot">
                        <label class="form-check-label" for="ExportToHubSpot">HubSpot</label>
                    </div>
                    <div class="form-check form-switch">
                        <input v-model="userSettings.exportWordTemplates" class="form-check-input" type="checkbox"
                            id="ExportToWord">
                        <label class="form-check-label" for="ExportToWord">Word document</label>
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-12">
                  <hr />
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <h5 class="form-label">My RSS feeds</h5>
                    <small>Paste these URLs into your social media scheduling platform (we recommend Publer)</small>
                    <br />
                    <strong>Facebook</strong>
                    <br />
                    <code>
                        {{ this.facebookRss }}
                    </code>
                    <br />
                    <strong>LinkedIn</strong>
                    <br />
                    <code>
                        {{ this.linkedInRss }}
                    </code>
                    <br />
                    <strong>Twitter X</strong>
                    <br />
                    <code>
                        {{ this.twitterRss }}
                    </code>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import vSelect from "vue-select";
import { createToast } from 'mosha-vue-toastify';

export default {
    components: {
        vSelect,
    },
    name: "MySettings",
    props: [

    ],
    data() {
        return {
            userSettings: {
                exportToBuffer: true,
                exportToHootSuite: true,
                exportToHubSpot: true,
                exportToPubler: true,
                exportToGrowably: true,
                exportWordTemplates: true,
                enableAutomatedPublishing: false,
                enableBlogContent: false,
                postDayForBlog: "1",
            },
            facebookRss: "",
            linkedInRss: "",
            twitterRss: "",
            selectedTimeZone: {
                label: '(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London',
                code: '0DD5A453-661C-4009-8F45-A956B8469EEA',
                selected: true,
            },
            selectedBlogDay: {   
                label: 'Monday',
                code: '1',
                selected: true,
            },
            disableBlogDaySelection: true,
        }
    },
    setup() {

        let userId = $('#UserId').val();
        let timezoneOptions = ref();
        let blogPublishOptions = ref();

        const getSettings = async () => {
            let { data } = await axios({
                url: `/api/settings/getsettings?id=${userId}`,
                method: 'GET',
            });

            return data;
        };

        const getAvailableTimeZones = async () => {
            const apiEndpoint = "/api/settings/gettimezones";
            let { data } = await axios({
                url: apiEndpoint,
                method: "GET"
            });

            timezoneOptions.value = data;
        };

        let selectedTimeRange = ref();

        const getFacebookRssUrl = async () => {
            let { data } = await axios({
                url: `/api/rss/geturl/?channel=facebook`,
                method: 'GET',
            });

            return data;
        };

        const getLinkedInRssUrl = async () => {
            let { data } = await axios({
                url: `/api/rss/geturl/?channel=linkedin`,
                method: 'GET',
            });

            return data;
        };

        const getTwitterRssUrl = async () => {
            let { data } = await axios({
                url: `/api/rss/geturl/?channel=twitter`,
                method: 'GET',
            });

            return data;
        };

        const getBlogPublishOptions = async () => {
            const blogApiEndpoint = "/api/settings/getblogpublishdays";
            let { data } = await axios({
                url: blogApiEndpoint,
                method: "GET"
            });

            blogPublishOptions.value = data;
        };

        return {
            getSettings,
            getFacebookRssUrl,
            getLinkedInRssUrl,
            getTwitterRssUrl,
            getAvailableTimeZones,
            getBlogPublishOptions,
            userId,
            timezoneOptions,
            selectedTimeRange,
            blogPublishOptions,
        }
    },
    async created() {
        this.userSettings = await this.getSettings();
        this.facebookRss = await this.getFacebookRssUrl();
        this.linkedInRss = await this.getLinkedInRssUrl();
        this.twitterRss = await this.getTwitterRssUrl();  
        await this.getAvailableTimeZones();
        await this.getBlogPublishOptions();

        this.selectedBlogDay.label = this.userSettings.postDayForBlog;
        this.selectedBlogDay.code = this.blogPublishOptions.find(x => x.label == this.userSettings.postDayForBlog).code;

        let startTimeSplit = this.userSettings.postPublishStartTime.split(':');
        let endTimeSplit = this.userSettings.postPublishEndTime.split(':');

        this.selectedTimeRange = [
            {
                hours: startTimeSplit[0],
                minutes: startTimeSplit[1],
                seconds: startTimeSplit[2],
            },
            {
                hours: endTimeSplit[0],
                minutes: endTimeSplit[1],
                seconds: endTimeSplit[2],
            }
        ];

        this.selectedTimeZone = this.timezoneOptions.find(x => x.code == this.userSettings.timeZoneId);
    },
    computed: {

    },
    watch: {
        selectedTimeZone() {
            this.userSettings.timeZoneId = this.selectedTimeZone.code;
        },
    },
    methods: {
        async save() {
            this.userSettings.postPublishStartTime = this.convertToTimespan(this.selectedTimeRange[0].hours, this.selectedTimeRange[0].minutes);
            this.userSettings.postPublishEndTime = this.convertToTimespan(this.selectedTimeRange[1].hours, this.selectedTimeRange[1].minutes);

            this.userSettings.postDayForBlog = this.selectedBlogDay.code;
            
            const apiEndpoint = '/api/settings/savesettings/'

            await axios.post(apiEndpoint, this.userSettings);

            $('#mySettings').offcanvas('hide');
            createToast('Settings saved', { position: 'bottom-right', type: 'success', showIcon: true });
            this.emitter.emit("save-settings", true);
        },
        convertToTimespan(hours, minutes) {
            let strHours = hours.toString();
            let strMinutes = minutes.toString();

            if (strHours.length == 1) {
                strHours = "0" + strHours;
            }

            if (strMinutes.length == 1) {
                strMinutes = "0" + strMinutes;
            }

            return `${strHours}:${strMinutes}:00`;
        }
    }
}
</script>

<style scoped></style>